import * as React from 'react'

import {
    Stack,
    List,
    ListItemButton,
    ListItemIcon,
    ListSubheader,
    Box,
    Avatar
} from '@mui/material'
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import BannerGoMidiaBH from '../../../../assets/images/header/bannerGomidiabh.png';

interface INavBar {

}



const MiddleHeader: React.FC<INavBar> = () => {
    const navigate = useNavigate();
    return (
        <Box
        sx={{
            width: '100%',
            marginTop: 3,
            maxWidth: '100%',
        }}
        >
            <a href="https://api.whatsapp.com/send/?phone=5531982213102&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                <img width='100%' src={BannerGoMidiaBH} alt="Banner GoMidiaBH"/>
            </a>
            
        </Box>
    );
}

export { MiddleHeader }