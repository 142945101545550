export const ModalCarrousel = ({ selectedImage, closeModal, showModal }: any) => {
    
    return (
        <div
            style={{
                display: showModal ? 'block' : 'none',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(0, 0, 0, 0.8)',
                zIndex: 999,
            }}
            onClick={closeModal}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <img
                    src={selectedImage}
                    alt="selected"
                    style={{
                        maxWidth: '100%'
                    }}
                />
            </div>
        </div>
    );
};
