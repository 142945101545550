import * as React from 'react'

import {
    Box,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { TopHeader } from './topheader'
import { MiddleHeader } from './middleheader'

interface INavBar {
    DadosContato: any;
}



const Header: React.FC<INavBar> = ({ DadosContato }) => {
    const navigate = useNavigate();

    return (
        <Box
        sx={{
            width: '100%',
        }}
        >
            <TopHeader DadosContato={DadosContato} />
            <MiddleHeader />
        </Box>
    );
}

export { Header }