/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as Yup from 'yup';

import {
  Avatar,
  Box,
  Container,
  List,
  ListSubheader,
  ListItem,
  Stack,
  useMediaQuery,
} from '@mui/material';

import { ItemText } from '../components/itemText';
import TextField from '../components/textfield';
import { Header } from '../components/layout/header';
import { Middle } from '../components/layout/middle';
import { Carrousel } from '../components/layout/carrousel';
import { CarrouselClientes } from '../components/layout/carrouselClientes';
import { Contato } from '../components/layout/contato';
import { Footer } from '../components/layout/footer';



const Home: React.FC = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const contatoRef = React.useRef<HTMLDivElement>(null);
  const ref = React.useRef(null);
  const [dadosRef, setDadosRef] = React.useState(null);

  const matches = useMediaQuery('(max-width:800px)');
  const matches2 = useMediaQuery('(max-width:1100px)');

  React.useEffect(() => {
    // Função para lidar com o redimensionamento da página
    const handleResize = () => {
      setDadosRef(ref.current);
      console.log(dadosRef);
      console.log(ref);
      // Adicione qualquer outra lógica que você deseja executar quando a página for redimensionada
    };

    // Adiciona um ouvinte de evento de redimensionamento
    window.addEventListener('resize', handleResize);

    // Chama a função de manipulação de redimensionamento quando o componente é montado
    handleResize();

    // Remove o ouvinte de evento quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dadosRef, ref]);

  return (
    <Box
      sx={{
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        margin: 0,
        maxWidth: '100%',
      }}
    >
      {!matches && !matches2 &&
        <Box>
          <Box
            sx={{
              marginRight: '10%',
              marginLeft: '10%'
            }}
          >
            <Header DadosContato={dadosRef}/>
            <Middle />
          </Box>
          <Box
            sx={{
              backgroundColor: '#F5F5F5',
              paddingBottom: 5
            }}
          >
            <Carrousel />
          </Box>
          <Box>
            <CarrouselClientes />
          </Box>
          <Box
            sx={{
              marginRight: '20%',
              marginLeft: '20%'
            }}
          >
            
            <div ref={ref}>
              <Contato/>
            </div>
            <Footer />
          </Box>
        </Box>
      }

      {!matches && matches2 &&
        <Box>
          <Box
            sx={{
              marginRight: '5%',
              marginLeft: '5%'
            }}
          >
            <Header DadosContato={dadosRef}/>
            <Middle />
          </Box>
          <Box
            sx={{
              backgroundColor: '#F5F5F5',
              paddingBottom: 5
            }}
          >
            <Carrousel />
          </Box>
          <Box>
            <CarrouselClientes />
          </Box>
          <Box
            sx={{
              marginRight: '10%',
              marginLeft: '10%'
            }}
          >
            <div ref={ref}>
              <Contato/>
            </div>
            <Footer />
          </Box>
        </Box>
      }
      {matches &&
        <Box>
          <Box
            sx={{
              marginRight: 2,
              marginLeft: 2,
            }}
          >
            <Header DadosContato={dadosRef}/>
            <Middle />
          </Box>
          <Box
            sx={{
              backgroundColor: '#F5F5F5',
              paddingBottom: 2
            }}
          >
            <Carrousel />
          </Box>
          <Box>
            <CarrouselClientes />
          </Box>
          <Box
            sx={{
              marginRight: 3,
              marginLeft: 3
            }}
          >
            <div ref={ref}>
              <Contato/>
            </div>
            <Footer />
          </Box>
        </Box>
      }     
    </Box>
  );
}

export { Home }