import * as React from 'react'

import {
    Box,
    ListItem,
    useMediaQuery,
    Paper
} from '@mui/material'
import { useNavigate } from 'react-router-dom'


import { ItemText } from '../../itemText';

interface INavBar {
    
}



const Contato: React.FC<INavBar> = ({ }) => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:800px)');
    const matches2 = useMediaQuery('(max-width:1100px)');


    return (
        <Box
            sx={{
                width: '100%',
                marginTop: 5,
                marginBottom: 5,
                maxWidth: '100%',
            }}
        >
            {!matches && !matches2 &&
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Paper
                    sx={{
                        padding: 5,
                        paddingRight: 15,
                        borderRadius: 5,
                        boxShadow: '0px 0px 50px 0px rgba(0, 0, 0, 0.2)',
                        transition: 'background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s'
                    }}
                >
                    <ListItem
                        sx={{
                            padding: 1,
                            flexDirection: 'row',
                            width: 'auto',
                            height: 'auto',
                            textAlign: 'left',
                        }}
                        
                    >
                        <ItemText 
                            primary="Contato"
                            secondary= "Solicite uma proposta"
                            primarySx={{
                                marginBottom: '20px',
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '36px',
                            }}
                            secondarySx={{
                                fontSize: '20px',
                                marginBottom: '20px',
                            }}
                        />
                    </ListItem>
                    <ListItem
                        sx={{
                            padding: 1,
                            flexDirection: 'column',
                            width: 'auto',
                            height: 'auto',
                            marginLeft: 10,
                        }}
                        
                    >
                        <ItemText 
                            primary="Whatsapp: (31) 98221 3102"
                            secondary= "Comercial: (31) 3248 7095"
                            primarySx={{
                                marginTop: '30px',
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                textAlign: 'center',
                            }}
                            secondarySx={{
                                marginBottom: 1,
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                textAlign: 'center',
                            }}
                        />
                    </ListItem>
                </Paper>
            </Box>
            }
            {!matches && matches2 &&
            <Box>
                <Paper
                    sx={{
                        padding: 5,
                        paddingRight: 15,
                        borderRadius: 5,
                        boxShadow: '0px 0px 50px 0px rgba(0, 0, 0, 0.2)',
                        transition: 'background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s'
                    }}
                >
                    <ListItem
                        sx={{
                            padding: 1,
                            flexDirection: 'row',
                            width: 'auto',
                            height: 'auto',
                            textAlign: 'left',
                        }}
                        
                    >
                        <ItemText 
                            primary="Contato"
                            secondary= "Solicite uma proposta"
                            primarySx={{
                                marginBottom: '20px',
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '32px',
                            }}
                            secondarySx={{
                                fontSize: '16px',
                                marginBottom: '20px',
                            }}
                        />
                    </ListItem>
                    <ListItem
                        sx={{
                            padding: 1,
                            flexDirection: 'column',
                            width: 'auto',
                            height: 'auto',
                            marginLeft: 10,
                        }}
                        
                    >
                        <ItemText 
                            primary="Whatsapp: (31) 98221 3102"
                            secondary= "Comercial: (31) 3248 7095"
                            primarySx={{
                                marginTop: '30px',
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '22px',
                                textAlign: 'center',
                            }}
                            secondarySx={{
                                marginBottom: 1,
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '22px',
                                textAlign: 'center',
                            }}
                        />
                    </ListItem>
                </Paper>
            </Box>
            }
            {matches &&
            
                <Box>
                    <Paper
                        sx={{
                            padding: 3,
                            paddingRight: 5,
                            borderRadius: 5,
                            boxShadow: '0px 0px 50px 0px rgba(0, 0, 0, 0.2)',
                            transition: 'background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s'
                        }}
                    >
                        <ListItem
                            sx={{
                                padding: 1,
                                flexDirection: 'row',
                                width: 'auto',
                                height: 'auto',
                                textAlign: 'left',
                            }}
                            
                        >
                            <ItemText 
                                primary="Contato"
                                secondary= "Solicite uma proposta"
                                primarySx={{
                                    marginBottom: '10px',
                                    color: '#eb5e35',
                                    fontWeight: 'bold',
                                    fontSize: '30px',
                                }}
                                secondarySx={{
                                    fontSize: '14px',
                                    marginBottom: '10px',
                                }}
                            />
                        </ListItem>
                        <ListItem
                            sx={{
                                padding: 1,
                                flexDirection: 'column',
                                width: 'auto',
                                height: 'auto',
                                marginLeft: 2,
                            }}
                            
                        >
                            <ItemText 
                                primary="Whatsapp: (31) 98221 3102"
                                secondary= "Comercial: (31) 3248 7095"
                                primarySx={{
                                    marginTop: '15px',
                                    color: '#eb5e35',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    textAlign: 'center',
                                }}
                                secondarySx={{
                                    marginBottom: 1,
                                    color: '#eb5e35',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    textAlign: 'center',
                                }}
                            />
                        </ListItem>
                    </Paper>
                </Box>
            }
        </Box>
    );
}

export { Contato }