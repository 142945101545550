import * as React from 'react'

import {
    Stack,
    List,
    ListItemButton,
    ListItemIcon,
    ListSubheader,
    Box,
    Avatar,
    useMediaQuery
} from '@mui/material'
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { AboutUs } from './aboutUs'

interface INavBar {

}



const Middle: React.FC<INavBar> = () => {
    const navigate = useNavigate();

    const matches = useMediaQuery('(max-width:800px)');
    return (
        <Box>
            {!matches && 
                <Box
                    sx={{
                        marginTop: 12,
                        marginRight: '5%',
                        marginLeft: '5%',
                        
                    }}
                    flexDirection= {matches ? 'column' : 'row'}
                    >
                    <AboutUs />
                </Box>
            }
            {matches && 
                <Box
                    sx={{
                        marginTop: 5,
                        marginRight: 3,
                        marginLeft: 3,
                        
                    }}
                    flexDirection= {matches ? 'column' : 'row'}
                    >
                    <AboutUs />
                </Box>
            }
            
        </Box>
    );
}

export { Middle }