import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import NotFoundPage from './pages/Error';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
