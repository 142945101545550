import * as React from 'react'

import {
    Stack,
    List,
    ListItemButton,
    ListItemIcon,
    ListSubheader,
    Box,
    Avatar,
    ListItem,
    useMediaQuery,
    Container,
    Paper
} from '@mui/material'
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';

import BannerGoMidiaBH from '../../../.assets/images/header/bannerGomidiabh.png';
import BannerGomidiaVerde from '../../../assets/images/middle/aboutUs/gomidia-transfacil-verde.jpg';
import BannerGomidiaAcelere from '../../../assets/images/middle/aboutUs/gomidia-acelereseunegocio-semtelefone.jpg';
import BannerGomidiaTodomundo from '../../../assets/images/middle/aboutUs/gomidia-todomundove-semtelefone.jpg';
import BannerGomidiaAcerte from '../../../assets/images/middle/aboutUs/gomidia-acertenamidia-semtelefone.jpg';

import { ItemText } from '../../itemText';

interface INavBar {

}



const Footer: React.FC<INavBar> = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:800px)');
    const matches2 = useMediaQuery('(max-width:1100px)');

    const Whatsap = 'https://api.whatsapp.com/send/?phone=5531982213102&text&type=phone_number&app_absent=0';
    const Facebook = 'https://www.facebook.com/gomidiabh';
    const Instagram = 'https://www.instagram.com/gomidiabh/';

    const handleWhatsAppClick = () => {
        const url = `${Whatsap}`;
        window.open(url, '_blank');
    };

    const handleFacebookClick = () => {
        const url = `${Facebook}`;
        window.open(url, '_blank');
    };

    const handleInstagramClick = () => {
        const url = `${Instagram}`;
        window.open(url, '_blank');
    };

    return (
        <Box
        sx={{
            width: '100%',
            marginTop: 5,
            marginBottom: 5,
            maxWidth: '100%',
        }}
        >
            {!matches && !matches2 &&
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 30
            }}
            >
                <ListItem
                    sx={{
                        padding: 1,
                        flexDirection: 'row',
                        width: 'auto',
                        height: 'auto',
                        textAlign: 'left',
                    }}
                    
                >
                    <ItemText 
                        primary={
                            <IconButton onClick={handleWhatsAppClick}>
                                <WhatsAppIcon
                                    sx={{
                                        fontSize: 50,
                                        color: 'green'
                                    }}
                                />
                            </IconButton>
                            
                        }
                        hideSecondary
                        primarySx={{
                            textAlign: 'center'
                        }}
                    />
                    <ItemText 
                        primary={
                            <IconButton onClick={handleFacebookClick}>
                                <FacebookIcon
                                    sx={{
                                        fontSize: 50,
                                        color: 'blue'
                                    }}
                                />
                            </IconButton>
                            
                        }
                        hideSecondary
                        primarySx={{
                            textAlign: 'center'
                        }}
                    />
                    <ItemText 
                        primary={
                            <IconButton onClick={handleInstagramClick}>
                                <InstagramIcon
                                    sx={{
                                        fontSize: 50,
                                        color: 'black'
                                    }}
                                />
                            </IconButton>
                        }
                        hideSecondary
                        primarySx={{
                            textAlign: 'center'
                        }}
                    />
                </ListItem>
            </Box>
            }
            {!matches && matches2 &&
            <Box
                sx={{
                    marginTop: 20,
                    alignItems: 'center',
                    width: 'auto',
                    height: 'auto',
                    flexDirection: 'row'
                }}
            >
                <ListItem
                    sx={{
                        padding: 1,
                    }}
                    
                >
                    <ItemText 
                        primary={
                            <IconButton onClick={handleWhatsAppClick}>
                                <WhatsAppIcon
                                    sx={{
                                        fontSize: 50,
                                        color: 'green'
                                    }}
                                />
                            </IconButton>
                            
                        }
                        hideSecondary
                        primarySx={{
                            textAlign: 'center'
                        }}
                    />
                    <ItemText 
                        primary={
                            <IconButton onClick={handleFacebookClick}>
                                <FacebookIcon
                                    sx={{
                                        fontSize: 50,
                                        color: 'blue'
                                    }}
                                />
                            </IconButton>
                            
                        }
                        hideSecondary
                        primarySx={{
                            textAlign: 'center'
                        }}
                    />
                    <ItemText 
                        primary={
                            <IconButton onClick={handleInstagramClick}>
                                <InstagramIcon
                                    sx={{
                                        fontSize: 50,
                                        color: 'black'
                                    }}
                                />
                            </IconButton>
                        }
                        hideSecondary
                        primarySx={{
                            textAlign: 'center'
                        }}
                    />
                </ListItem>
                
            </Box>
            }
            {matches &&
            
                <Box
                    sx={{
                        marginTop: 10,
                        alignItems: 'center'
                    }}
                >
                    <ListItem
                    sx={{
                        padding: 1,
                    }}
                    
                >
                    <ItemText 
                        primary={
                            <IconButton onClick={handleWhatsAppClick}>
                                <WhatsAppIcon
                                    sx={{
                                        fontSize: 40,
                                        color: 'green'
                                    }}
                                />
                            </IconButton>
                            
                        }
                        hideSecondary
                        primarySx={{
                            textAlign: 'center'
                        }}
                    />
                    <ItemText 
                        primary={
                            <IconButton onClick={handleFacebookClick}>
                                <FacebookIcon
                                    sx={{
                                        fontSize: 40,
                                        color: 'blue'
                                    }}
                                />
                            </IconButton>
                            
                        }
                        hideSecondary
                        primarySx={{
                            textAlign: 'center'
                        }}
                    />
                    <ItemText 
                        primary={
                            <IconButton onClick={handleInstagramClick}>
                                <InstagramIcon
                                    sx={{
                                        fontSize: 40,
                                        color: 'black'
                                    }}
                                />
                            </IconButton>
                        }
                        hideSecondary
                        primarySx={{
                            textAlign: 'center'
                        }}
                    />
                </ListItem>
                </Box>
            }
        </Box>
    );
}

export { Footer }