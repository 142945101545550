/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react'

import {
    Stack,
    List,
    ListItemButton,
    ListItemIcon,
    ListSubheader,
    Box,
    Avatar,
    ListItem,
    useMediaQuery
} from '@mui/material'
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'

import IMGOne from '../../../assets/images/middle/carrousel/IMG_backup1-300x200.jpg';
import IMGTwo from '../../../assets/images/middle/carrousel/IMG_backup2-300x200.jpg';
import IMGThree from '../../../assets/images/middle/carrousel/IMG_backup3-300x200.jpg';
import IMGFour from '../../../assets/images/middle/carrousel/IMG_backup4-300x200.jpg';
import IMGFive from '../../../assets/images/middle/carrousel/IMG_backup5-237x300.jpg';
import IMGSix from '../../../assets/images/middle/carrousel/IMG_backup6-300x200.jpg';
import IMGSeven from '../../../assets/images/middle/carrousel/IMG_backup7-300x200.jpg';

import IMGOneFull from '../../../assets/images/middle/carrousel/IMG_backupFull1.jpg';
import IMGTwoFull from '../../../assets/images/middle/carrousel/IMG_backupFull2.jpg';
import IMGThreeFull from '../../../assets/images/middle/carrousel/IMG_backupFull3.jpg';
import IMGFourFull from '../../../assets/images/middle/carrousel/IMG_backupFull4.jpg';
import IMGFiveFull from '../../../assets/images/middle/carrousel/IMG_backupFull5.jpg';
import IMGSixFull from '../../../assets/images/middle/carrousel/IMG_backupFull6.jpg';
import IMGSevenFull from '../../../assets/images/middle/carrousel/IMG_backupFull7.jpg';


import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import { ItemText } from '../../itemText';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { ModalCarrousel } from './modal'

interface INavBar {

}



const Carrousel: React.FC<INavBar> = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:800px)');

    const [showModal, setShowModal] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState('');



    return (
        <Box
            sx={{
                width: '100%',
                marginTop: 5,
                marginBottom: 5,
                maxWidth: '100%',
            }}
        >
            {/* {!matches && 
                <Box>
                    <Carousel>
                        <img width="100%" src="https://picsum.photos/800/600?random=1" />
                        <img width="100%" src="https://picsum.photos/800/600?random=2" />
                        <img width="100%" src="https://picsum.photos/800/600?random=3" />
                    </Carousel>
                </Box>
            }
            {matches &&
            
                <Box>
                    <Carousel>
                        <img width="100%" src="https://picsum.photos/800/600?random=1" />
                        <img width="100%" src="https://picsum.photos/800/600?random=2" />
                        <img width="100%" src="https://picsum.photos/800/600?random=3" />
                    </Carousel>
                </Box>
            } */}
            {!matches && 
                <Box
                    sx={{
                        width: '100%',
                        paddingRight: 40,
                        marginRight: '10%',
                        marginLeft: '10%',
                        
                    }}
                >
                    <ListItem
                        sx={{
                            padding: 1,
                            flexDirection: 'row',
                            paddingLeft: 10,                           
                        }}
                        
                    >
                        <ItemText 
                            primary="Algumas campanhas de backbus"
                            hideSecondary 
                            primarySx={{
                                marginBottom: 1,
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '32px'
                            }}
                            secondarySx={{
                                fontSize: '24px'
                            }}
                        />
                    </ListItem>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={1}
                        slidesPerView={3}
                        navigation
                        pagination={{ clickable: true }}
                        onSwiper={(swiper: any) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            maxWidth: '100%',
                            height: '250px',
                            "--swiper-navigation-color": "#000",
                            "--swiper-navigation-paddingBottom": '80px',
                        }}
                    >
                        <SwiperSlide>
                            <img
                                width='70%'
                                src={IMGOne}
                                onClick={() => {
                                    setSelectedImage(IMGOneFull);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                width='70%'
                                src={IMGTwo}
                                onClick={() => {
                                    setSelectedImage(IMGTwoFull);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                width='70%'
                                src={IMGThree}
                                onClick={() => {
                                    setSelectedImage(IMGThreeFull);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                width='70%'
                                src={IMGFour}
                                onClick={() => {
                                    setSelectedImage(IMGFourFull);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                width='70%'
                                src={IMGFive}
                                onClick={() => {
                                    setSelectedImage(IMGFiveFull);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                width='70%'
                                src={IMGSix}
                                onClick={() => {
                                    setSelectedImage(IMGSixFull);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                width='70%'
                                src={IMGSeven}
                                onClick={() => {
                                    setSelectedImage(IMGSevenFull);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                    </Swiper>
                    <ModalCarrousel
                        selectedImage={selectedImage}
                        closeModal={() => setShowModal(false)}
                        showModal={showModal}
                    />
                </Box>
            }
            {matches && 
                <Box
                    sx={{
                        width: '100%',
                        marginRight: '2%',
                        marginLeft: '2%',
                    }}
                >
                    <ListItem
                        sx={{
                            padding: 1,
                            flexDirection: 'column',
                            paddingLeft: 5, 
                        }}
                        
                    >
                        <ItemText 
                            primary="Algumas campanhas de backbus"
                            hideSecondary 
                            primarySx={{
                                marginBottom: 1,
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '22px'
                            }}
                            secondarySx={{
                                fontSize: '16px'
                            }}
                        />
                    </ListItem>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={0}
                        slidesPerView={2}
                        navigation
                        pagination={{ clickable: true }}
                        onSwiper={(swiper: any) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            maxWidth: '90%',
                            height: '150px',
                            "--swiper-navigation-color": "#000",
                            "--swiper-navigation-size": "25px",
                        }}
                    >
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img
                                width='70%'
                                src={IMGOne}
                                onClick={() => {
                                    setSelectedImage(IMGOne);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%'
                            }}
                        >
                            <img
                                width='70%'
                                src={IMGTwo}
                                onClick={() => {
                                    setSelectedImage(IMGTwo);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img
                                width='70%'
                                src={IMGThree}
                                onClick={() => {
                                    setSelectedImage(IMGThree);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img
                                width='70%'
                                src={IMGFour}
                                onClick={() => {
                                    setSelectedImage(IMGFour);
                                    setShowModal(true);
                                }}
                            />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGFive} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGSix} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGSeven} />
                        </SwiperSlide>
                    </Swiper>
                </Box>
            }
        </Box>
    );
}

export { Carrousel }