import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#eb5e35',
    },
    secondary: {
      main: '#442461',
    },
    background: {
      default: '#FFFAFA'
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

export default theme;