import * as React from 'react'

import {
    Stack,
    Box,
    Button
} from '@mui/material'
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';

interface TopHeaderProps {
    DadosContato: any;
}

const TopHeader: React.FC<TopHeaderProps> = ({ DadosContato }) => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:800px)');
    const matches2 = useMediaQuery('(max-width:1100px)');

    const openNewTab = (url: any) => {
        window.open(url, '_blank');
    };


    const handleClick = () => {
        const ref = DadosContato;
        ref?.scrollIntoView({behavior: 'smooth'});
    };

    React.useEffect(() => {
        console.log(DadosContato);
    },[])

    return (
        <Box
        sx={{
            width: '100%',
        }}
        >

            {!matches && !matches2 &&
                <Box>
                    <Stack
                        alignItems="center"
                        justifyContent='right'
                        flexDirection='row'
                        sx={{
                            marginRight: 10,
                            marginLeft: 10,
                        }}
                    >
                        <Button
                            onClick={() => openNewTab('https://api.whatsapp.com/send/?phone=5531982213102&text&type=phone_number&app_absent=0')}
                            sx={{
                                margin: 1,
                                }}
                                variant="contained"
                        >
                            <WhatsAppIcon /> &nbsp; (31) 98221-3102
                        </Button>
                        <Button
                            onClick={handleClick}
                            sx={{
                                margin: 1,
                                backgroundColor: '#442461'
                            }}
                                variant="contained"
                        >
                            <MailIcon /> &nbsp; Solicite Orçamento
                        </Button>
                    </Stack>
                </Box>
            }
            {!matches && matches2 &&
                <Box>
                    <Stack
                        alignItems="center"
                        justifyContent='right'
                        flexDirection='row'
                        sx={{
                            marginRight: 5,
                            marginLeft: 5,
                        }}
                    >
                        <Button
                            onClick={() => openNewTab('https://api.whatsapp.com/send/?phone=5531982213102&text&type=phone_number&app_absent=0')}
                            sx={{
                                margin: 1,
                                }}
                                variant="contained"
                            >
                                <WhatsAppIcon /> &nbsp; (31) 98221-3102
                            </Button>
                        <Button
                            onClick={handleClick}
                            sx={{
                                margin: 1,
                                backgroundColor: '#442461'
                            }}
                                variant="contained"
                            >
                                <MailIcon /> &nbsp; Solicite Orçamento
                            </Button>
                    </Stack>
                </Box>
            }
            {matches && 
                <Box>
                <Stack
                    alignItems="center"
                    justifyContent='center'
                    flexDirection='column'
                    sx={{
                        marginRight: 4,
                        marginLeft: 4,
                    }}
                >
                    <Button
                        onClick={() => openNewTab('https://api.whatsapp.com/send/?phone=5531982213102&text&type=phone_number&app_absent=0')}
                        sx={{
                            margin: 1,
                        }}
                        variant="contained"
                    >
                        <WhatsAppIcon /> &nbsp; (31) 98221-3102
                    </Button>
                    <Button
                        onClick={handleClick}
                        sx={{
                            margin: 1,
                            backgroundColor: '#442461'
                            }}
                            variant="contained"
                        >
                            Solicite um Orçamento
                        </Button>
                </Stack>
            </Box>
            }
        </Box>
    );
}

export { TopHeader }