import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, [navigate]);

    return (
        <div>
            <p style={{ textAlign: "center" }}>
                <Link to="/">Error</Link>
            </p>
        </div>
    );
};

export default NotFoundPage;