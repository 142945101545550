import * as React from 'react'

import {
    Stack,
    List,
    ListItemButton,
    ListItemIcon,
    ListSubheader,
    Box,
    Avatar,
    ListItem,
    useMediaQuery
} from '@mui/material'
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'

import IMGOne from '../../../assets/images/middle/carrouselclientes/bernoulli.png';
import IMGTwo from '../../../assets/images/middle/carrouselclientes/pifpaf.png';
import IMGThree from '../../../assets/images/middle/carrouselclientes/cotemig.png';
import IMGFour from '../../../assets/images/middle/carrouselclientes/chromos.png';
import IMGFive from '../../../assets/images/middle/carrouselclientes/sesi.png';
import IMGSix from '../../../assets/images/middle/carrouselclientes/sesc.png';
import IMGSeven from '../../../assets/images/middle/carrouselclientes/uniao-nacional-estudantes.png';
import IMGEight from '../../../assets/images/middle/carrouselclientes/unimed.png';
import IMGNine from '../../../assets/images/middle/carrouselclientes/una.png';
import IMGTen from '../../../assets/images/middle/carrouselclientes/araujo.png';
import IMGEleven from '../../../assets/images/middle/carrouselclientes/vilma.png';
import IMGTwelve from '../../../assets/images/middle/carrouselclientes/colegio-arnaldo.png';


import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import { ItemText } from '../../itemText';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

interface INavBar {

}



const CarrouselClientes: React.FC<INavBar> = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:800px)');


    return (
        <Box
            sx={{
                width: '100%',
                marginTop: 5,
                marginBottom: 5,
                maxWidth: '100%',
            }}
        >
            {/* {!matches && 
                <Box>
                    <Carousel>
                        <img width="100%" src="https://picsum.photos/800/600?random=1" />
                        <img width="100%" src="https://picsum.photos/800/600?random=2" />
                        <img width="100%" src="https://picsum.photos/800/600?random=3" />
                    </Carousel>
                </Box>
            }
            {matches &&
            
                <Box>
                    <Carousel>
                        <img width="100%" src="https://picsum.photos/800/600?random=1" />
                        <img width="100%" src="https://picsum.photos/800/600?random=2" />
                        <img width="100%" src="https://picsum.photos/800/600?random=3" />
                    </Carousel>
                </Box>
            } */}
            {!matches && 
                <Box
                    sx={{
                        width: '100%',
                        paddingRight: 40,
                        marginRight: '10%',
                        marginLeft: '10%',
                        
                    }}
                >
                    <ListItem
                        sx={{
                            padding: 1,
                            flexDirection: 'row',
                            paddingLeft: 10,                           
                        }}
                        
                    >
                        <ItemText 
                            primary="Clientes"
                            hideSecondary 
                            primarySx={{
                                marginBottom: 1,
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '32px'
                            }}
                            secondarySx={{
                                fontSize: '24px'
                            }}
                        />
                    </ListItem>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={1}
                        slidesPerView={4}
                        pagination={{
                            clickable: true,
                        }}
                        onSwiper={(swiper: any) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            maxWidth: '100%',
                            height: '230px',
                        }}
                    >
                        <SwiperSlide>
                            <img width='80%' src={IMGOne} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGTwo} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGThree} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGFour} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGFive} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGSix} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGSeven} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGEight} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGNine} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGTen} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGEleven} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img width='80%' src={IMGTwelve} />
                        </SwiperSlide>
                    </Swiper>
                </Box>
            }
            {matches && 
                <Box
                    sx={{
                        width: '100%',
                        marginRight: '2%',
                        marginLeft: '2%',
                    }}
                >
                    <ListItem
                        sx={{
                            padding: 1,
                            flexDirection: 'column',
                            paddingLeft: 5, 
                        }}
                        
                    >
                        <ItemText 
                            primary="Algumas campanhas de backbus"
                            hideSecondary 
                            primarySx={{
                                marginBottom: 1,
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '22px'
                            }}
                            secondarySx={{
                                fontSize: '16px'
                            }}
                        />
                    </ListItem>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={0}
                        slidesPerView={4}
                        pagination={{ clickable: true }}
                        onSwiper={(swiper: any) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            maxWidth: '90%',
                            height: '110px',
                        }}
                    >
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGOne} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%'
                            }}
                        >
                            <img width='70%' src={IMGTwo} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGThree} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGFour} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGFive} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGSix} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGSeven} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGEight} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGNine} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGTen} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGEleven} />
                        </SwiperSlide>
                        <SwiperSlide
                            style={{
                                width:'50%',
                            }}
                        >
                            <img width='70%' src={IMGTwelve} />
                        </SwiperSlide>
                    </Swiper>
                </Box>
            }
        </Box>
    );
}

export { CarrouselClientes }