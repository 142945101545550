import * as React from 'react'

import {
    Stack,
    List,
    ListItemButton,
    ListItemIcon,
    ListSubheader,
    Box,
    Avatar,
    ListItem,
    useMediaQuery
} from '@mui/material'
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import BannerGoMidiaBH from '../../../../assets/images/header/bannerGomidiabh.png';
import BannerGomidiaVerde from '../../../../assets/images/middle/aboutUs/gomidia-transfacil-verde.jpg';
import BannerGomidiaAcelere from '../../../../assets/images/middle/aboutUs/gomidia-acelereseunegocio-semtelefone.jpg';
import BannerGomidiaTodomundo from '../../../../assets/images/middle/aboutUs/gomidia-todomundove-semtelefone.jpg';
import BannerGomidiaAcerte from '../../../../assets/images/middle/aboutUs/gomidia-acertenamidia-semtelefone.jpg';

import { ItemText } from '../../../itemText';

interface INavBar {

}



const AboutUs: React.FC<INavBar> = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:700px)');
    const matches2 = useMediaQuery('(max-width:1100px)');


    return (
        <Box
        sx={{
            width: '100%',
            marginTop: 5,
            marginBottom: 10,
            maxWidth: '100%',
        }}
        >
            {!matches && !matches2 &&
            <Box>
                <ListItem
                    sx={{
                        padding: 1,
                    }}
                    
                >
                    <ItemText 
                        primary="Quem somos"
                        secondary= "GoMidia é a mais nova plataforma de Mídia Exterior | Fora de casa de BH. Contamos com a extensão das mídias backbus e busdoor nos ônibus da capital mineira. Acelere seus Negócios e Acerte na Mídia que Todo Mundo Vê. Gerenciamos, comercializamos e veiculamos os espaços anunciados dos 2.600 ônibus da frota da transfácil, além de 6 grandes terminais e 37 estações de ônibus."
                        primarySx={{
                            marginBottom: 1,
                            color: '#eb5e35',
                            fontWeight: 'bold',
                            fontSize: '32px'
                        }}
                        secondarySx={{
                            fontSize: '24px'
                        }}
                    />
                    <img
                        width='22%'
                        src={BannerGomidiaVerde}
                        alt="Banner GoMidiaBH"
                        style={{
                            margin: 5,
                            padding: 10
                        }}
                    />
                </ListItem>
                <ListItem>
                    <ItemText 
                        primary="Diferenciais"
                        secondary= "A veiculação de mídia em Backbus e busdoor atinge públicos diversos e de forma itinerante. Essa mídia alcança um alto número de pessoas e nao se se limita aos passageiros das linhas, o que traz mais visibilidade para quem anuncia. É mídia em movimento com mais impacto para a sua empresa, de forma eficaz e rentável."
                        primarySx={{
                            marginBottom: 1,
                            color: '#eb5e35',
                            fontWeight: 'bold',
                            fontSize: '32px'
                        }}
                        secondarySx={{
                            fontSize: '24px'
                        }}
                    />
                </ListItem>
                <Stack
                    alignItems="center"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    flexDirection="row"
                    sx={{
                        width: '100%',
                        padding: 1,
                        margin: 1,
                    }}
                >
                    <img
                        width='25%'
                        src={BannerGomidiaAcelere}
                        alt="Banner GoMidiaBH"
                        />
                    <img
                        width='25%'
                        src={BannerGomidiaTodomundo}
                        alt="Banner GoMidiaBH"
                    />
                    <img
                        width='25%'
                        src={BannerGomidiaAcerte}
                        alt="Banner GoMidiaBH"
                    />
                </Stack>
            </Box>
            }
            {!matches && matches2 &&
            <Box>
                <ListItem
                    sx={{
                        padding: 1,
                    }}
                    
                >
                    <ItemText 
                        primary="Quem somos"
                        secondary= "GoMidia é a mais nova plataforma de Mídia Exterior | Fora de casa de BH. Contamos com a extensão das mídias backbus e busdoor nos ônibus da capital mineira. Acelere seus Negócios e Acerte na Mídia que Todo Mundo Vê. Gerenciamos, comercializamos e veiculamos os espaços anunciados dos 2.600 ônibus da frota da transfácil, além de 6 grandes terminais e 37 estações de ônibus."
                        primarySx={{
                            marginBottom: 1,
                            color: '#eb5e35',
                            fontWeight: 'bold',
                            fontSize: '32px'
                        }}
                        secondarySx={{
                            fontSize: '24px'
                        }}
                    />
                    <img
                        width='35%'
                        src={BannerGomidiaVerde}
                        alt="Banner GoMidiaBH"
                        style={{
                            marginLeft: 10,
                        }}
                    />
                </ListItem>
                <ListItem>
                    <ItemText 
                        primary="Diferenciais"
                        secondary= "A veiculação de mídia em Backbus e busdoor atinge públicos diversos e de forma itinerante. Essa mídia alcança um alto número de pessoas e nao se se limita aos passageiros das linhas, o que traz mais visibilidade para quem anuncia. É mídia em movimento com mais impacto para a sua empresa, de forma eficaz e rentável."
                        primarySx={{
                            marginBottom: 1,
                            color: '#eb5e35',
                            fontWeight: 'bold',
                            fontSize: '32px'
                        }}
                        secondarySx={{
                            fontSize: '24px'
                        }}
                    />
                </ListItem>
                <Stack
                    alignItems="center"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    flexDirection="row"
                    sx={{
                        width: '100%',
                        padding: 1,
                        margin: 1,
                    }}
                >
                    <img
                        width='25%'
                        src={BannerGomidiaAcelere}
                        alt="Banner GoMidiaBH"
                        />
                    <img
                        width='25%'
                        src={BannerGomidiaTodomundo}
                        alt="Banner GoMidiaBH"
                    />
                    <img
                        width='25%'
                        src={BannerGomidiaAcerte}
                        alt="Banner GoMidiaBH"
                    />
                </Stack>
            </Box>
            }
            {matches &&
            
                <Box>
                    <ListItem
                        sx={{
                            padding: 1,
                            flexDirection: 'column'
                        }}
                        
                    >
                        <ItemText 
                            primary="Quem Somos"
                            secondary= "GoMidia é a mais nova plataforma de Mídia Exterior | Fora de casa de BH. Contamos com a extensão das mídias backbus e busdoor nos ônibus da capital mineira. Acelere seus Negócios e Acerte na Mídia que Todo Mundo Vê. Gerenciamos, comercializamos e veiculamos os espaços anunciados dos 2.600 ônibus da frota da transfácil, além de 6 grandes terminais e 37 estações de ônibus."
                            primarySx={{
                                marginBottom: 1,
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '24px'
                            }}
                            secondarySx={{
                                fontSize: '16px'
                            }}
                        />
                        <img width='60%' src={BannerGomidiaVerde} alt="Banner GoMidiaBH"/>
                    </ListItem>
                    <ListItem>
                        <ItemText 
                            primary="Diferenciais"
                            secondary= "A veiculação de mídia em Backbus e busdoor atinge públicos diversos e de forma itinerante. Essa mídia alcança um alto número de pessoas e nao se se limita aos passageiros das linhas, o que traz mais visibilidade para quem anuncia. É mídia em movimento com mais impacto para a sua empresa, de forma eficaz e rentável."
                            primarySx={{
                                marginBottom: 1,
                                color: '#eb5e35',
                                fontWeight: 'bold',
                                fontSize: '24px'
                            }}
                            secondarySx={{
                                fontSize: '16px'
                            }}
                        />
                    </ListItem>
                    <Stack
                        alignItems="center"
                        flexDirection="column"
                        sx={{
                            width: '100%',
                            padding: 1,
                            margin: 1,
                        }}
                    >
                        <img
                            width='70%'
                            src={BannerGomidiaAcelere}
                            alt="Banner GoMidiaBH"
                            style={{ marginBottom: 15 }}
                            />
                        <img
                            width='70%'
                            src={BannerGomidiaTodomundo}
                            alt="Banner GoMidiaBH"
                            style={{ marginBottom: 15 }}
                        />
                        <img
                            width='70%'
                            src={BannerGomidiaAcerte}
                            alt="Banner GoMidiaBH"
                            style={{ marginBottom: 15 }}
                        />
                    </Stack>
                        </Box>
                    }
                </Box>
    );
}

export { AboutUs }