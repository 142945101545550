import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import theme from '../theme';

interface IProvider {
    children: React.ReactNode
}

interface IContext {

}

const Context = React.createContext<IContext>({

})

const Provider: React.FC<IProvider> = ({children}) => {
    const value = React.useMemo(()=> ({

    }),[])
    return (
        <Context.Provider value={value}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </Context.Provider>
    )
}

export { Provider, Context }