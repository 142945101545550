import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as ProviderTheme } from './contexts/theme';
import { BrowserRouter } from 'react-router-dom';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ProviderTheme>
        <App/>
      </ProviderTheme>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
